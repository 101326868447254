import React from "react"

const Brochure = ({brochure}) => {
    return (
        <section className="common-wrapper overflow-hidden bg-grey-4 py-15">
            <div className="text-align-center text-blue-dark w-half my-auto w-full-xs px-20-xs">
            <h3 className="mt-0 uppercase f45 font-bold">{brochure.commercialTagline}</h3>
            <a className="button bg-blue-dafinity text-white" target="_blank" rel="noopener noreferrer" href={brochure.commercialBrochureLink.file.url}>Plaquette</a>
            </div>
        </section>
    )
}

export default Brochure