import React from 'react'
import Header from "../components/header"
import Footer from "../components/footer"

import "../less/style.less"

const Layout = ({ location, children }) => {

  return (
    <div className="app-wrapper">
      <Header />
      <main className="">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
