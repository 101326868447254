import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Header = () => {

    const data = useStaticQuery(graphql`
    query Header {
        contentfulHeader {
          name
          logo {
            title
            description
            fluid(maxWidth: 150) {
                ...GatsbyContentfulFluid_noBase64
            }
          }
          socials {
            socials {
              name
              icon {
                title
                description
                fluid(maxWidth: 150) {
                    ...GatsbyContentfulFluid_noBase64
                }
              }
              link
            }
          }
          backgroundColor
          subBackgroundColor
          contactUsText
          phoneNumber
        }
      }
  `)

    return (
        <header className="common-wrapper" style={{ backgroundColor: data.contentfulHeader.backgroundColor }}>
            <nav className="py-6 px-4 flex items-center flex-col-md">
                <Link className="w-25" to={"/"}>
                    {   data.contentfulHeader.logo && data.contentfulHeader.logo.fluid && 
                        <Img
                            fluid={data.contentfulHeader.logo ? data.contentfulHeader.logo.fluid : ''}
                            alt={data.contentfulHeader.logo ? data.contentfulHeader.logo.description : ''}
                        />
                    }
                </Link>
                <ul className="flex items-center flex-grow-1 justify-evenly my-4-md flex-col-xs text-align-center">
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/cfo-part-time/"}>CFO Part-time</Link>
                    </li>
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/transaction-services/"}>Transaction services</Link>
                    </li>
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/rpa-services/"}>RPA services</Link>
                    </li>
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/track-records/"}>Track-record</Link>
                    </li>
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/team-jobs/"}>Team & Jobs</Link>
                    </li>
                    <li className="uppercase text-white font-bold f5 mx-2-md my-2-xs">
                        <Link to={"/actualites/"}>Actualités</Link>
                    </li>
                </ul>
                <a className="button" target="_blank" href="mailto:hello@dafinity.fr?subject=Prise de contact | Dafinity">
                    {data.contentfulHeader.contactUsText}
                </a>
            </nav>
            <div className="py-3 px-4 flex items-center justify-between" style={{ backgroundColor: data.contentfulHeader.subBackgroundColor }}>
                <ul className="flex justify-evenly items-center">
                    {data.contentfulHeader.socials.socials.map(social => {
                        return (
                            <li className="ml-3 w-6" key={social.id}>
                                <a className="block" href={social.link}>
                                    <Img
                                        fluid={social.icon ? social.icon.fluid : ''}
                                        alt={social.icon ? social.icon.description : ''}
                                    />
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </header>
    )
}

export default Header