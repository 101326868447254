/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import DafinityImage from "../assets/illustration/franck.svg"
import Favicon from "../../static/favicon.ico"

function SEO({ description, lang, meta, keywords, title }) {

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            link={[
                { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${Favicon}` },
                { rel: 'icon', type: 'image/png', sizes: "32x32", href: `${Favicon}` },
                { rel: 'shortcut icon', type: 'image/png', href: `${Favicon}` },
            ]}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `og:image`,
                    content: DafinityImage,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:image`,
                    content: DafinityImage,
                },
                {
                    name: `twitter:creator`,
                    content: `Dafinity`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ]
                .concat(
                    keywords.length > 0
                        ? {
                            name: `keywords`,
                            content: keywords.join(`, `),
                        }
                        : []
                )
                .concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `fr`,
    meta: [],
    keywords: [],
    description: ``,
}

export default SEO
