import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Footer {
      contentfulFooter {
        contactUsText
        links {
          slug
          title
        }
        description {
            childMarkdownRemark {
                html
            }
        }
        socials {
          socials {
            name
            icon {
              title
              description
              fluid(maxWidth: 150) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            link
          }
        }
        logo {
          title
          description
          fluid(maxWidth: 150) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <footer className="common-wrapper overflow-hidden bg-grey-4">
      <div className="bg-darkest-blue text-align-center w-full flex flex-col justify-center items-center py-13">
        <a
          className="button mb-7" target="_blank" href="mailto:hello@dafinity.fr?subject=Prise de contact | Dafinity"
        >
          {data.contentfulFooter.contactUsText}
        </a>
        <ul className="flex justify-evenly items-center">
          {data.contentfulFooter.socials[0].socials.map(social => {
            return (
              <li className="ml-3 w-6" key={social.id}>
                <a className="block" href={social.link}>
                  {social.icon && social.icon.fluid &&
                    <Img
                      fluid={social.icon ? social.icon.fluid : ''}
                      alt={social.icon ? social.icon.description : ''}
                    />
                  }
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="flex flex-col justify-center items-center bg-blue-dark py-15 text-align-center">
        <Link className="w-25 mb-8" to={"/"}>
          {data.contentfulFooter.logo && data.contentfulFooter.logo.fluid &&
            <Img
              fluid={data.contentfulFooter.logo.fluid}
              alt={data.contentfulFooter.logo.description}
            />
          }
        </Link>
        <div className="block f4 font-normal lh-4 text-grey-2 mt-0 px-40 px-10-md px-0-xs w-half w-full-md mb-8 text-preline" dangerouslySetInnerHTML={{ __html: data.contentfulFooter.description.childMarkdownRemark.html }}></div>
        <ul className="flex">
          {data.contentfulFooter.links.map(link => {
            return (<li key={link.id} className="f6 cursor-pointer"><Link className="underline text-white" to={link.slug || '/'}>{link.title}</Link></li>)
          })}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
